<template>
    <div>
      <InstallmentsTable
        :useTax="useTax"
        :type="paymentMethod.type"
        :installments="payment.installments"
      />
    </div>
  </template>
  <script>
    import { getFirstUtilDay } from '../../utils/financialHelper'
    export default {
      components: { 
        InstallmentsTable: () => import('@/modules/financial/components/PaymentMethodInstallmentsTable')
      },
      props: {
        useTax: Boolean,
        installmentQuantity: Number,
        payment: Object,
        paymentMethod: Object,
      },
      mounted() {
        !this.payment.id && (this.payment.installments = this.inflateInstallments())
      },
      methods: {
        inflateInstallments() {
          const tax = this.getPaymentMethodTax(this.installmentQuantity)
          const totalAmount = this.payment.amount
          const amount = totalAmount / this.installmentQuantity
          
          return new Array(this.installmentQuantity)
            .fill({})
            .reduce((acc, el, index) => {
              const position = index + 1
              const dueDate = getFirstUtilDay(this.getFutureDate(this.payment.due_date, index))
  
              acc.push({
                position: position,
                installment_count: this.installmentQuantity,
                type: 'installment',
                due_date: dueDate,
                payment_date: null,
                change: this.paymentMethod.change,
                tax,
                amount: amount - tax,
                installment_value: totalAmount,
                status: 'pending'
              });
  
              return acc;
            }, []);
        },
        getPaymentMethodTax(position) {
          const installment = this.paymentMethod.installments.find(el => el.position === position)
          return installment && this.useTax ? installment.value : 0
        },
        getFutureDate(dueDate, months) {
          if (!dueDate) return null;
          return this.moment(dueDate).add(months, 'month');
        },
        getPaymentDate(paymentMethod, dueDate) {
          return paymentMethod.change === 'auto' ? dueDate : null
        },
      },
      watch: {
        'payment.amount'() {
          this.payment.installments = this.inflateInstallments()
        },
        'payment.due_date'() {
          this.payment.installments = this.inflateInstallments()
        },
        installmentQuantity() {
          this.payment.installments = this.inflateInstallments()
        }
      }
    }
  </script>
  